@import "colours";
@import "variables";
@import "typography";

.input-fields {
  background-color: $mc-white-01;
  width: 100%;
  border: none;
  font-family: $body-font;
  min-width: 195px !important;
  padding: 9px 10px;
}

.input-group-addon {
  padding: 4px 20px;
  font-weight: 400;
  line-height: 1;
  color: $mc-white-01;
  text-align: center;
  background-color: $mc-blue-01;
  border: 1px solid $mc-blue-01;
  width: 1%;
  margin-right: 5px;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
