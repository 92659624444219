@import "typography";

.pulse-table-header-item {
  font-family: $header-font;
}


.pulse-table-row-item {
  font-family: $body-font;
}
