@import "colours";
@import "typography";

nav.react-contextmenu {
  position: fixed;
  width: 200px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 0;
  background-color: $mc-grey-01;
  z-index: 99;
  box-shadow: 0 0 20px rgba(0,0,0,.5);
  color: $mc-blue-01;
}
.react-contextmenu .react-contextmenu-item:hover{
  //background: #cccccc;
  background-color: $mc-yellow-01
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
  background-color: $mc-yellow-01;
  color: $mc-blue-01;
}

.react-contextmenu .react-contextmenu-item{
  font-size: 14px;
  display: block;
  text-decoration: none;
  padding: 10px 15px;
  cursor: pointer;
  user-select: none;
  transition: .2s;
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
}

.react-contextmenu-item span {
  font-family: $body-font;
}

.contextmenu-title {
  background-color: $mc-blue-01;
  color: $mc-grey-01;
  padding: 5px 20px;
}

.react-contextmenu-item span{
  margin-left: 5px;
}
