@import "colours";
@import "variables";

.pulse-breadcrumb-wrapper {
  margin: 0;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.pulse-breadcrumb-item {
  width: auto;
  padding: 10px;
  color: $mc-blue-01;

  background-color: $mc-grey-01;

  &:not(:first-of-type) {
    transform: skew(-20deg);
    border-left: 2px solid $mc-blue-01;
  }

  &:not(:first-of-type) h6 {
    transform: skew(20deg);
  }

  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: none !important;
  }

  &:first-child::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 92px;
    border-style: solid;
    border-color: $mc-yellow-01 transparent transparent transparent;
    border-width: 44px 17px 20px 0;
  }

  &:first-child:hover::after {
    border-color: $mc-yellow-hover transparent transparent transparent;
    transition: all ease $animation-duration;
  }

  &:first-child:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 92px;
    border-style: solid;
    border-color: $mc-grey-01 transparent transparent transparent;
    border-width: 44px 17px 20px 0;
  }

  &:first-child:not(:last-child):hover::after {
    border-color: $mc-grey-03 transparent transparent transparent;
    transition: all ease $animation-duration;
  }

  &:hover {
    background-color: $mc-grey-03;
    transition: all ease $animation-duration;
    cursor: pointer;
  }

  &:last-child {
    background-color: $mc-yellow-01;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:last-child:hover {
    background-color: $mc-yellow-hover;
    transition: all ease $animation-duration;
    cursor: pointer;
  }
}


@keyframes shine-normal {
  0% {
    background: linear-gradient(
                    30deg,
                    $mc-white-01 0%,
                    $mc-grey-01 25%
    );
  }

  25% {
    background: linear-gradient(
                    30deg,
                    $mc-grey-01 0%,
                    $mc-white-01 25%,
                    $mc-grey-01 50%);
  }

  50% {
    background: linear-gradient(
                    30deg,
                    $mc-grey-01 0%,
                    $mc-white-01 50%,
                    $mc-grey-01 75%);
  }

  75% {
    background: linear-gradient(
                    30deg,
                    $mc-grey-01 0%,
                    $mc-white-01 75%,
                    $mc-grey-01 100%);
  }

  100% {
    background: linear-gradient(
                    30deg,
                    $mc-grey-01 0%,
                    $mc-white-01 100%);
  }
}


@keyframes shine-active {
  0% {
    background: linear-gradient(
                    30deg,
                    $mc-white-01 0%,
                    $mc-yellow-01 25%
    );
  }

  25% {
    background: linear-gradient(
                    30deg,
                    $mc-yellow-01 0%,
                    $mc-white-01 25%,
                    $mc-yellow-01 50%);
  }

  50% {
    background: linear-gradient(
                    30deg,
                    $mc-yellow-01 0%,
                    $mc-white-01 50%,
                    $mc-yellow-01 75%);
  }

  75% {
    background: linear-gradient(
                    30deg,
                    $mc-yellow-01 0%,
                    $mc-white-01 75%,
                    $mc-yellow-01 100%);
  }

  100% {
    background: linear-gradient(
                    30deg,
                    $mc-yellow-01 0%,
                    $mc-white-01 100%);
  }
}

