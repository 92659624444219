@import "colours";

.loading-bar-wrapper {
  margin-top: 5px;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: $mc-grey-01;
  border-radius: 0.5rem;
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2), inset 0 2px 1px rgba(0, 0, 0, .2);
}

.loading-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: $mc-white-01;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
  border-radius: 0.5rem !important;
}

.stripey {
  position: relative;
  background: linear-gradient(90deg, $mc-white-01 50%, transparent 50%);
  background-color: $mc-yellow-01;
  background-size: 200%;
  background-position: 100%;
  transform: scale(1);
  transition: 0.3s;
}

.stripey:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
                  45deg,
                  transparent 25%,
                  transparent 30%,
                  transparent 35%,
                  rgba(238, 238, 238, 0.5) 35%,
                  rgba(238, 238, 238, 0.5) 70%,
                  transparent 70%
  );
  animation: shift 2s linear infinite;
  background-size: 60px 100%;
  box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.2), inset 0 -2px 1px rgba(0, 0, 0, 0.2);
}

@keyframes shift {
  to {
    background-position: 60px 100%;
  }
}
