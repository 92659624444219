@import "colours";

.number-container {
  border-radius: 50%;
  background: $mc-blue-01;
  border: 1px solid $mc-blue-01;
  width: 18px;
  height: 18px;
  top: 0;
  left: 0;
  transform: translate(70%, -60%);
  color: $mc-white-01;
}

.number-container > p {
  margin: 0 !important;
  font-size: 12px;
}
