@import "colours";
@import "variables";
@import "typography";


.btn-base-lg,
.btn-base {
  color: $mc-white-01;
  text-align: center;
  font-family: $header-font;
  font-weight: 400;
  font-size: 12px;
  padding: 5px 10px;
  margin: 0 2px 5px 0;
  text-decoration: none;
  display: inline-block;
  border: 1px solid transparent;
}

.btn-base-lg {
  height: 30px;
  font-size: 12px;
}

.btn-base {
  height: 25px;
  font-size: 12px;
}

.btn-base-lg.rounded-corner {
  border-radius: 15px;
}
.btn-base.rounded-corner {
  border-radius: 12.5px;
}

.btn-base-lg.scaled-font,
.btn-base.scaled-font {
  font-size: clamp(0.6em, unquote("0.3vw + 0.45rem"), 0.8em) !important;
}

.btn-base.green-btn,
.btn-base.light-blue-btn,
.btn-base.orange-btn,
.btn-base.red-btn,
.btn-base.yellow-btn,
.btn-base.dark-blue-btn,
.btn-base-lg.green-btn-lg,
.btn-base-lg.light-blue-btn-lg,
.btn-base-lg.orange-btn-lg,
.btn-base-lg.red-btn-lg,
.btn-base-lg.yellow-btn-lg,
.btn-base-lg.dark-blue-btn-lg {
  width: auto;
  &:hover {
    cursor: pointer;
    transition: all $animation-duration ease;
    -webkit-transition: all $animation-duration ease;
  }
  &:disabled {
    cursor: default;
    transition: all $animation-duration ease;
    -webkit-transition: all $animation-duration ease;
  }
}

.btn-base.green-btn,
.btn-base-lg.green-btn-lg {
  background-color: $mc-green-01;
  border: 1px solid $mc-green-01;

  &:hover {
    background-color: $mc-green-hover;
    border: 1px solid $mc-green-hover;
  }

  &:disabled {
    background-color: $mc-green-disabled;
    border: 1px solid $mc-green-disabled;
  }
}

.btn-base.light-blue-btn,
.btn-base-lg.light-blue-btn-lg {
  background-color: $mc-blue-04;
  border: 1px solid $mc-blue-04;

  &:hover {
    background-color: $mc-light-blue-hover;
    border: 1px solid $mc-light-blue-hover;
  }

  &:disabled {
    background-color: $mc-light-blue-disabled;
    border: 1px solid $mc-light-blue-disabled;
  }
}

.btn-base.orange-btn,
.btn-base-lg.orange-btn-lg {
  background-color: $mc-orange-01;
  border: 1px solid $mc-orange-01;

  &:hover {
    background-color: $mc-orange-hover;
    border: 1px solid $mc-orange-hover;
  }

  &:disabled {
    background-color: $mc-orange-disabled;
    border: 1px solid $mc-orange-disabled;
  }
}

.btn-base.red-btn,
.btn-base-lg.red-btn-lg {
  background-color: $mc-red-01;
  border: 1px solid $mc-red-01;

  &:hover {
    background-color: $mc-red-hover;
    border: 1px solid $mc-red-hover;
  }

  &:disabled {
    background-color: $mc-red-disabled;
    border: 1px solid $mc-red-disabled;
  }
}

.btn-base.yellow-btn,
.btn-base-lg.yellow-btn-lg {
  background-color: $mc-yellow-01;
  border: 1px solid $mc-yellow-01;
  color: $mc-blue-01;

  &:hover {
    background-color: $mc-yellow-hover;
    border: 1px solid $mc-yellow-hover;
  }

  &:disabled {
    background-color: $mc-yellow-disabled;
    border: 1px solid $mc-yellow-disabled;
  }
}

.btn-base.dark-blue-btn,
.btn-base-lg.dark-blue-btn-lg {
  background-color: $mc-blue-01;
  border: 1px solid $mc-blue-01;

  &:hover {
    background-color: $mc-dark-blue-hover;
    border: 1px solid $mc-dark-blue-hover;
  }

  &:disabled {
    background-color: $mc-dark-blue-disabled;
    border: 1px solid $mc-dark-blue-disabled;
  }
}
