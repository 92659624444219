@import "colours";
@font-face {
  font-family:'Magistral';
  src: url("../fonts/Magistral-Bold.ttf");

}
@font-face {
  font-family:'Montserrat';
  src: url('../fonts/Montserrat-Regular.ttf');
}

$header-font: Magistral;
$body-font: Montserrat;

li, a {
  text-decoration: none !important;
}

.body-font,
p, a {
  font-family: $body-font;
}

h1, h2, h3, h4, h5, h6,
.header-font {
  font-family: $header-font;
}

.point-eight-em {
  font-size: 0.8em !important;
}
.one-em {
  font-size: 1em !important;
}
.one-point-zero-five-em {
  font-size: 1.05em !important;
}

.table-ellipsis {
  font-size: 12px !important;
  text-align: center !important;
  overflow: hidden !important;
  line-height: 1rem !important;
  max-height: 4rem !important;
  -webkit-box-orient: vertical !important;
  display: block !important;
  display: -webkit-box !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 6 !important;
}
