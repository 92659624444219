@import "~bootstrap";
@import "~react-toastify/dist/ReactToastify.css";
@import "~store-fetch-wrappers/dist/styles/scss/index";
@import "~pulse-modal/dist/styles/scss/index";
@import "~pulse_table/dist/styles/scss/index";

@import "core/animations";
@import "core/breadcrumb";
@import "core/buttons";
@import "core/colours";
@import "core/directoryItem";
@import "core/form";
@import "core/icons";
@import "core/inputs";
@import "core/interactivity";
@import "core/loading-bar";
@import "core/navigation";
@import "core/page";
@import "core/rightClickMenu";
@import "core/sidebar";
@import "core/table";
@import "core/toast";
@import "core/tooltip";
@import "core/typography";
@import "core/uploadFileForm";
@import "core/uploadManager";
@import "core/utility";
@import "core/variables";
@import "core/x-drive-tooltip";

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
}
