@import "colours";
.directoryItemWrapper {
  position: relative;
  height: 160px;
  width: 160px;
  padding: 15px 15px;
  text-align: center;
}

.icon-wrapper {
  &:hover {
    text-decoration: $mc-blue-01 underline !important;
  }
}
