@import "colours";
:root {
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  border-radius: 0 !important;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  color: $mc-blue-01;
  background: $mc-grey-01;
  border: 1px solid $mc-grey-01;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  width: 250px;
  height: auto;
  -webkit-box-shadow: 0 0 6px 5px $mc-grey-03;
  box-shadow: 0 0 6px 5px $mc-grey-03;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 6px;
  margin-left: -6px;

}

/* Absolute positioning */
.Tooltip-Tip.top-left {
  left: auto;
  right: 30px;
  top: 100%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.top-left::before {
  left: auto;
  right: -12px;
  top: 25%;
  transform: translateX(0) translateY(-40%);
  border-left-color: $mc-blue-01;
}
